import React from "react";
import { FC } from "react";

export interface Link {
  title: string;
  url: string;
}

export interface LinksSection {
  title: string;
  url?: string;
  links: Link[];
}

export const Links: FC<{ links: Link[] }> = ({ links }) => {
  return (
    <div className="flex flex-wrap gap-2">
      {links.map((link, index) => (
        <div
          key={index}
          className="flex justify-items-center place-items-center justify-center gap-2"
        >
          <a href={link.url} className="block text-gray-500 hover:underline">
            {link.title}
          </a>
          {index < links.length - 1 && <div className="text-gray-300">|</div>}
        </div>
      ))}
    </div>
  );
};

interface LinksSectionGroupProps {
  title?: string;
  links?: LinksSection[];
}

export const LinksSectionGroup: FC<LinksSectionGroupProps> = ({
  title,
  links,
}) => {
  return (
    <section className="mt-10">
      {title && <h3 className="text-xl font-bold mb-6">{title}</h3>}

      {links && (
        <div className="flex flex-wrap gap-2 mb-4">
          {links.map((section, index) => (
            <LinksSection
              key={index}
              title={section.title}
              url={section.url}
              links={section.links}
              variant="compact"
            />
          ))}
        </div>
      )}
    </section>
  );
};

interface LinksSectionProps {
  title: string;
  url?: string;
  links: Link[];
  variant?: "default" | "compact";
}

export const LinksSection: FC<LinksSectionProps> = (section) => {
  const variant = section.variant || "default";
  return (
    <section className="flex flex-wrap w-full mb-6">
      {variant === "compact" && (
        <h4 className="text-md font-semibold w-full text-left mb-2">
          {section.title}
        </h4>
      )}

      {variant === "default" && (
        <h3 className="text-xl font-bold mb-4">{section.title}</h3>
      )}

      <div className="w-full">
        <Links links={section.links} />
      </div>
    </section>
  );
};
