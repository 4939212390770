import React, { FC } from "react";

const getYoutubeIframeURL = (src: string) => {
  if (src.includes("youtu.be")) {
    const videoID = src.split("/").pop();
    return `https://www.youtube.com/embed/${videoID}`;
  }

  if (src.includes("youtube.com")) {
    const url = new URLSearchParams(src.split("?")[1]);
    const videoID = url.get("v");
    return `https://www.youtube.com/embed/${videoID}`;
  }

  return "";
};

const getVimeoIframeURL = (src: string) => {
  const videoID = src.split("/").pop();

  return `https://player.vimeo.com/video/${videoID}?controls=1&transparent=1`;
};

const getIframeURL = (src: string) => {
  if (src.includes("vimeo")) {
    return getVimeoIframeURL(src);
  }

  if (src.includes("youtu.be") || src.includes("youtube")) {
    return getYoutubeIframeURL(src);
  }

  return undefined;
};

interface VideoProps {
  src: string;
}

export const Video: FC<VideoProps> = ({ src }) => {
  const iframeSrc = getIframeURL(src);

  if (!iframeSrc) {
    return <React.Fragment />;
  }

  return (
    <iframe
      src={iframeSrc}
      className="w-full h-full"
      frameBorder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowFullScreen
    />
  );
};
