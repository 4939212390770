import "react-alice-carousel/lib/alice-carousel.css";
import React, { FC, useEffect, useRef, useState } from "react";
import { Trans } from "@lingui/macro";
import { ArrowPrev } from "../Icon/icons/ArrowPrev";
import ModalAnimation from "../ModalAnimation/ModalAnimation";
import { ArrowNext } from "../Icon/icons/ArrowNext";
import { Close } from "../Icon/icons/Close";
import { Video } from "../Video";
import { Image } from "../Image";
import Carousel from "react-alice-carousel";

interface GalleryItem {
  src: string;
  alt: string;
  type: "image" | "video";
  caption?: string;
}

interface IModalProps {
  items: GalleryItem[];
  currentIndex: number;
  onRequestClose: () => void;
  visible: boolean;
}

const getSourceType = (src: string) => {
  if (src.includes("vimeo")) {
    return "video";
  }

  if (src.includes("youtu.be") || src.includes("youtube.com")) {
    return "video";
  }

  return "image";
};

const GalleryItem: FC<GalleryItem> = ({ src, alt }) => {
  const sourceType = getSourceType(src);

  return (
    <div className="relative shadow-md rounded-lg w-full h-75-vh">
      {sourceType === "image" && (
        <Image
          src={src}
          alt={alt || ""}
          className="w-full object-contain h-75-vh"
          layout="fill"
          activeCache={true}
        />
      )}

      {sourceType === "video" && <Video src={src} />}
    </div>
  );
};

const ModalBigPicture: FC<IModalProps> = ({
  items,
  currentIndex,
  onRequestClose,
  visible,
}) => {
  const bigImageRef = useRef<HTMLDivElement>(null);
  // prettier-ignore
  const [currentImageCarroseulIndex, setCurrentImageCarroseulIndex] =
    useState(currentIndex);
  const [modalCarrouselVisible, setModalCarrouselVisible] = useState(false);

  const handleOpenImageBigSize = (index: number) => {
    setModalCarrouselVisible(true);
    setCurrentImageCarroseulIndex(index);
  };

  const onClickChangeImage = (order: "prev" | "next") => {
    if (currentImageCarroseulIndex === 0 && order === "prev") {
      return;
    }
    if (currentImageCarroseulIndex >= items.length - 1 && order === "next") {
      return;
    }
    bigImageRef.current?.classList.replace("opacity-1", "opacity-0");
    setTimeout(() => {
      setCurrentImageCarroseulIndex(
        currentImageCarroseulIndex + (order === "prev" ? -1 : +1)
      );
      bigImageRef.current?.classList.replace("opacity-0", "opacity-1");
    }, 200);
  };

  useEffect(() => {
    if (visible) {
      handleOpenImageBigSize(currentIndex);
    } else {
      setModalCarrouselVisible(false);
    }
  }, [visible]);

  useEffect(() => {
    setCurrentImageCarroseulIndex(currentIndex);
  }, [currentIndex]);

  const handleKey = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      onRequestClose();
    }

    if (e.key === "ArrowLeft") {
      setCurrentImageCarroseulIndex((old) => (old >= 1 ? old - 1 : old));
    }
    if (e.key === "ArrowRight") {
      setCurrentImageCarroseulIndex((old) =>
        old < items.length - 1 ? old + 1 : old
      );
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKey, false);

    return () => {
      document.removeEventListener("keydown", handleKey, false);
    };
  }, []);

  return (
    <ModalAnimation visible={modalCarrouselVisible}>
      <div className="flex flex-col bg-black h-full relative">
        <div className="flex flex-col">
          <div className="flex flex-row">
            <button
              onClick={() => onRequestClose()}
              className="flex flex-row px-4 py-2 m-8 hover:bg-gray-800 rounded-full items-center"
            >
              <Close className="w-6 h-6 text-white mb-0.5" />
              <p className="text-white ml-1 font-normal text-sm">
                <Trans>Close</Trans>
              </p>
            </button>
            <div className="flex absolute left-1/2 top-8 flex-row items-center mx-auto mb-8">
              <p className="text-white ">
                {currentImageCarroseulIndex + 1}/{items.length}
              </p>
            </div>
          </div>
        </div>

        <div className="flex w-full h-full items-center px-4 max-h-75-vh my-auto">
          <div
            ref={bigImageRef}
            className="flex w-full h-5/6 mb-24 justify-center items-center opacity-1 transition-opacity duration-200"
          >
            {items[currentImageCarroseulIndex]?.type === "video" ? (
              <Video src={items[currentImageCarroseulIndex]?.src} />
            ) : (
              <div className="flex w-full h-75vh align-middle relative">
                <div className="hidden md:flex items-center h-full absolute left-8 z-10">
                  <button
                    className={`flex rounded-full bg-white p-2 ${
                      currentImageCarroseulIndex === 0
                        ? "opacity-50"
                        : "opacity-100"
                    }`}
                    onClick={() => onClickChangeImage("prev")}
                  >
                    <ArrowPrev className="w-8 h-8 text-black" />
                  </button>
                </div>
                <Carousel
                  mouseTracking
                  animationEasingFunction="ease"
                  items={items.map((item, key) => (
                    <GalleryItem key={`${item.src}-${key}`} {...item} />
                  ))}
                  activeIndex={currentImageCarroseulIndex}
                  disableDotsControls={true}
                  disableButtonsControls={true}
                  onSlideChanged={(e) => {
                    setCurrentImageCarroseulIndex(e.slide);
                  }}
                />
                <div className="hidden md:flex items-center h-full absolute right-8 z-10">
                  <button
                    className={`flex rounded-full p-2 bg-white ${
                      currentImageCarroseulIndex === items.length - 1
                        ? "opacity-50"
                        : "opacity-100"
                    }`}
                    onClick={() => onClickChangeImage("next")}
                  >
                    <ArrowNext className="w-8 h-8 text-black" />
                  </button>
                </div>
              </div>
            )}
          </div>
          {items[currentImageCarroseulIndex]?.caption && (
            <div className="absolute bottom-0 w-full bg-gray-800 bg-opacity-50 px-4 py-2 text-white truncate text-center">
              <div className="max-h-12 overflow-hidden overflow-ellipsis">
                {items[currentImageCarroseulIndex]?.caption}
              </div>
            </div>
          )}
        </div>
      </div>
    </ModalAnimation>
  );
};

export default ModalBigPicture;
