import { BreadcrumbItem } from "@components/ui/Breadcrumb";
import React, { FC } from "react";
import MicroFormat from "..";

interface Props {
  items: BreadcrumbItem[];
}

const BreadcrumbMicroFormat: FC<Props> = ({ items }) => {
  const itemListElement: Array<unknown> = [];

  items.map((item, index) => {
    itemListElement.push({
      "@type": "ListItem",
      position: index + 1,
      item: {
        "@id": item.href,
        name: item.text,
      },
    });
  });

  const data = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement,
  };

  return <MicroFormat data={data} />;
};

export default BreadcrumbMicroFormat;
